import React from "react"

export const BlockWithIcon = ({ children, extraClasses = '' }) => (
  <div className={`block-with-icon ${extraClasses}`}>
    { children }
  </div>
)

export const BlockWithIconWrap = ({ children, layout = 'horizontal', extraClasses = '' }) => (
  <div className={`block-with-icon-wrap ${extraClasses} layout-${layout}`}>
    { children }
  </div>
)

export const BlockWithIconIcon = ({ children, iconName = '' }) => (
  <div className={`icon ${iconName}`}>
    { children }
  </div>
)

export const BlockWithIconText = ({ children, extraClasses = '' }) => (
  <div className={`text ${extraClasses}`}>
    { children }
  </div>
)
