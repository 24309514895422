import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";
import ButtonLink from "../components/elements/ButtonLink";
import {FaEnvelope, FaPhone} from "react-icons/all";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap
} from "../components/elements/BlockWithIcon";
import {Person, PersonAbout, PersonPhoto} from "../components/elements/PersonProfile";
import ToddLevine from "../components/images/ToddLevine";
import JonathanKatz from "../components/images/JonathanKatz";
import SuzetteDiMascio from "../components/images/SuzetteDiMascio";
import LogoColor from "../components/images/LogoColor";

const About = () => (
  <Index>
    <Seo title="About Us" />
    <PageHeader>
      <h1>About Us</h1>
      <h2>A Legacy of Expertise</h2>
      <p className="no-margins">
        <ButtonLink href="/request-demo" text="Schedule a Demo" type="primary" />
      </p>
    </PageHeader>
    <Container extraContainerClasses="space-out">
      <p className="lead text-blue align-center">
        InCircle offers a novel approach to IG patient selection that cohesively <br className="hide-large" />
        aligns the wisdom of medicine with the precision of algorithmic data, <br className="hide-large" />
        to significantly improve care, minimize waste and decrease drug spend.
      </p>
    </Container>
    <Container extraInnerClasses="align-center">
      <div id="about-logo">
        <LogoColor />
      </div>
      <h2 className="text-blue">The Idea Behind InCircle</h2>
      <p className="text-gray">
        The idea behind InCircle was birthed following the findings of the largest review of <br className="hide-large" />
        immunoglobulin specialty drug therapy utilization. The INSIGHTS study, included a <br className="hide-large" />
        review of 248 patient progress notes, lab data, and electrodiagnostic studies supplied to <br className="hide-large" />
        insurance companies for IVIG in neuropathy. The compiled data, which included referrals <br className="hide-large" />
        from 154 physicians in 26 states, produced findings that were troubling: only 32% of <br className="hide-large" />
        patients receiving treatment were determined to actually have an immune neuropathy; <br className="hide-large" />
        half of patients diagnosed with neuropathies that were clearly not immune-mediated; <br className="hide-large" />
        and only 15% clearly met electrodiagnostic criteria for a demyelinating neuropathy.
      </p>
      <p className="text-gray">
        <b>Without question, a better approach was needed.</b>
      </p>
      <p>&nbsp;</p>
    </Container>
    <Container extraContainerClasses="background-very-light-gray">
      <BlockWithIconWrap layout="vertical">
        <BlockWithIcon>
          <BlockWithIconIcon iconName="algorithms" />
          <BlockWithIconText>
            <h2>Algorithm Development</h2>
            <p>Spurred by a desire to improve IG prescription accuracy, the core <br className="hide-large" />
              team at InCircle began strategizing to develop an algorithm <br className="hide-large" />
              -based approach that would capture only the most vital patient <br className="hide-large" />
              data and essentially match the right drug to the right patient. <br className="hide-large" />
              The InCircle solution eliminates the need to involve MDs in the <br className="hide-large" />
              decision-making process, while leveraging the experience and <br className="hide-large" />
              knowledge of a panel of expert reviewers who have access to <br className="hide-large" />
              real data.</p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="arrows" />
          <BlockWithIconText>
            <h2>The result?</h2>
            <p>InCircle is poised to revolutionize the cumbersome IG review <br className="hide-large" />
              process, deliver longitudinal patient tracking information, <br className="hide-large" />
              provide open access to accurate data, and fundamentally <br className="hide-large" />
              transform the way IG is prescribed. It also facilitates a significant <br className="hide-large" />
              process improvement by shortening the time for approvals, <br className="hide-large" />
              ultimately reducing the overall drug spend – a win-win for <br className="hide-large" />
              physicians, payors and specialty drug manufacturers.</p>
          </BlockWithIconText>
        </BlockWithIcon>
      </BlockWithIconWrap>
    </Container>
    <Container id="about-people">
      <h2 className="align-center text-blue">Meet our team</h2>
      <Person>
        <PersonPhoto>
          <ToddLevine />
        </PersonPhoto>
        <PersonAbout>
          <h2>Todd Levine, M.D.</h2>
          <p>
            Todd Dylan Levine, MD received his medical degree from Duke University in <br className="hide-large" />
            Durham, North Carolina. He completed a residency in Neurology and a Fellowship <br className="hide-large" />
            in Neuromuscular Disease at Washington University School of Medicine in <br className="hide-large" />
            St. Louis, Missouri, and has been a specialist in Neuromuscular disorders for the <br className="hide-large" />
            past 20 years.
          </p>
          <p>
            Dr. Levine currently serves as the Director of the Neurology Department at Honor <br className="hide-large" />
            Health in Phoenix Arizona, is the founder of the multi-disciplinary ALS Clinic, and <br className="hide-large" />
            is one of the founders of Corinthian Reference Labs and Cutaneous Neurodiagnostic, <br className="hide-large" />
            a national reference labs dedicated to using skin biopsies for diagnosing neurologic <br className="hide-large" />
            disorders such as Parkinson’s Disease and Alzheimer’s Disease.
          </p>
          <p>
            Dr. Levine is board certified by the American Board of Psychiatry and Neurology <br className="hide-large" />
            and the American Board of Electrodiagnostic Medicine. He is a fellow of the <br className="hide-large" />
            American Association of Neuromuscular and Electrodiagnostic Medicine and a member <br className="hide-large" />
            of the American Academy of Neurology. He is the past Chairman of the Neuromuscular <br className="hide-large" />
            division of the American Academy of Neurology and the Arizona State Liaison for the <br className="hide-large" />
            American Academy of Neuroelectrodiagnostic Medicine. Dr. Levine conducts extensive <br className="hide-large" />
            research in diseases of the nerve and muscle. He has published his findings in <br className="hide-large" />
            numerous peer-reviewed journals and lectures both nationally and internationally <br className="hide-large" />
            on these disorders.
          </p>
          <p>
            <a href="http://www.phoenixneurology.com/staff/levine.php" target="_blank" rel="noreferrer">
              http://www.phoenixneurology.com/staff/levine.php
            </a>
          </p>
        </PersonAbout>
      </Person>
      <Person>
        <PersonPhoto>
          <JonathanKatz />
        </PersonPhoto>
        <PersonAbout>
          <h2>Jonathan S. Katz, M.D.</h2>
          <p>
            Dr. Katz currently practices Neurology in San Francisco, and is  Board Certified in <br className="hide-large" />
            Adult Neurology by the American Board of Psychiatry and Neurology and a member of the <br className="hide-large" />
            American Academy of Neurology.
          </p>
          <p>
            Dr. Katz is President of the ALS Research Group and serves on the National Medical <br className="hide-large" />
            Advisory Boards for the Myasthenia Gravis Foundation and the Guillain-Barre Syndrome <br className="hide-large" />
            Foundation International.
          </p>
          <p>
            Honored as one of The Best Doctors in America, Dr. Katz also serves as Adjunct <br className="hide-large" />
            Professor in Neurology at Kansas University. He co-conceived the InCircle concept <br className="hide-large" />
            with Dr Levine, and currently serves as an advisor for further algorithm development.
          </p>
          <p>
            <a href="http://www.cpmc.org/dr-jonathan-s-katz.html" target="_blank" rel="noreferrer">
              http://www.cpmc.org/dr-jonathan-s-katz.html
            </a>
          </p>
        </PersonAbout>
      </Person>
      <Person>
        <PersonPhoto>
          <SuzetteDiMascio />
        </PersonPhoto>
        <PersonAbout>
          <h2 className="no-margin-bottom">
            Suzette DiMascio, CHE, CMCE, CPC <br className="hide-large" />Executive Vice President
          </h2>
          <h3>InCircle Review</h3>
          <p>
            As an innovator, thought leader, sought-after key note speaker and educator <br className="hide-large" />
            on all things Specialty Pharmacy & Rare/Orphan Disease related, Suzette <br className="hide-large" />
            is recognized internationally as a trusted advisor that accelerates business <br className="hide-large" />
            growth while possessing a relentless drive to transform patient care. A self-<br className="hide-large" />
            described Passionate Advocate, Suzette has demonstrated success in <br className="hide-large" />
            delivering cross functional solutions to increase Patient Choice and Access <br className="hide-large" />
            across the healthcare continuum.
          </p>
          <p>
            As a reflection of her dedication to the industry & servant leadership <br className="hide-large" />
            philosophy, Suzette received APPA’s Hall of Fame Award, honoring the ways <br className="hide-large" />
            she champions to improve patient care & access, as well as mentoring women <br className="hide-large" />
            through the Women in Specialty Pharmacy Organization she founded in 2013. <br className="hide-large" />
            Suzette also serves as a volunteer on the Executive team for the HBA Global <br className="hide-large" />
            Ambassador Program, as a worldwide advocate for Diversity and Inclusion, <br className="hide-large" />
            thriving on inspiring and mentoring up and coming leaders.
          </p>
          <p>
            In addition to serving as a Board Member for NASP, HBA & the State of FL <br className="hide-large" />
            Innovation Caucus, Suzette also serves as an Advisory Board Member for Atlas <br className="hide-large" />
            Health and SpringHealth Live, two up and coming patient technology <br className="hide-large" />
            solutions. Appointed by Gov. Rick Scott to the FL Board of Employee Leasing, <br className="hide-large" />
            the rigorous selection process to be appointed to a State Board is a true <br className="hide-large" />
            testament to Ms. DiMascio’s stellar ethical reputation in the industry she has <br className="hide-large" />
            served for over 25 years.
          </p>
          <p>
            Invited by the Governor’s office to spearhead FL's Talent Caucus, she was 1 of <br className="hide-large" />
            50 women selected to participate in a Prestigious Executive Leadership <br className="hide-large" />
            Program at Tuck School of Business-Dartmouth & was 1 of only 14 women to <br className="hide-large" />
            receive the Dorothy Brothers Scholarship award by WBENC.
          </p>
          <p>
            Suzette founded CSI Specialty Group In 2002, serving as President/CEO for 17 <br className="hide-large" />
            years. Under her leadership, honors include being named as one of Consulting <br className="hide-large" />
            Magazine's Fastest Growing Firms, Goldman Sach’s Top Growing Businesses & <br className="hide-large" />
            recipient for an unprecedented 4 years in a row, of the coveted <br className="hide-large" />
            Commonwealth Institute’s Top 50 Women Led Business, from 2014-2018.
          </p>
        </PersonAbout>
      </Person>
    </Container>
    <div id="contact-us-block">
      <h2 className="text-blue align-center large">Contact us</h2>
      <div id="contact-us-halves">
        <a href="tel:+18006595855">
          <FaPhone size="30" /> <br />
          (800) 659-5855
        </a>
        <a href="mailto:info@InCircleReview.com">
          <FaEnvelope size="30" /> <br />
          info@InCircleReview.com
        </a>
      </div>
      <p className="align-center">
        <ButtonLink href="/schedule-demo/" text="Schedule a Free Demo Today!" type="primary" />
      </p>
      <p>&nbsp;</p>
      <p className="hide-medium">&nbsp;</p>
    </div>
  </Index>
)

export default About
