import React from "react"

export const Person = ({ children }) => (
  <div className="person">
    { children }
  </div>
)

export const PersonAbout = ({ children, extraClasses = '' }) => (
  <div className={`about ${extraClasses}`}>
    { children }
  </div>
)

export const PersonPhoto = ({ children, extraClasses = '' }) => (
  <div className={`photo ${extraClasses}`}>
    { children }
  </div>
)
