import React from "react"
import Container from "../layout/Container";

const PageHeader = ({ children, extraClasses = '' }) => (
  <div className={`page-header ${extraClasses}`}>
    <Container>
      { children }
    </Container>
  </div>
)

export default PageHeader
